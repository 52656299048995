body, html {
  margin: 0;
  padding: 0;
  height: 100%; /* Garante que o body ocupe toda a altura da tela */
  overflow: hidden; /* Remove o scroll na página inteira */
}

body strong,
body .subTitle {
  /* color: #2a1710 !important; */
}

body img {
  /* pointer-events: none; */
  /* Bloqueia interações com a imagem */
  -webkit-user-drag: none;
  /* Bloqueia arrastar no WebKit */
}


.App {
  width: 100vw;
  height: 100vh;
  overflow: auto; /* Permite rolar o conteúdo sem exibir as barras de rolagem */
  -ms-overflow-style: none; /* Para IE 10+ */
  scrollbar-width: none; /* Para Firefox */
  font-family: "Montserrat", serif !important;
  position: relative;
}

.App::-webkit-scrollbar {
  display: none; /* Remove as barras de rolagem no WebKit (Chrome, Safari, etc.) */
}
