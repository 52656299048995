.containerSobre {
  width: 100vw;
  background-image: url(../../Assets/textura-papel-reciclado.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerSobre .containerFundadores {
  display: flex;
  width: 90vw;
  gap: 10px;
  justify-content: center;
  align-items: center;
}


.containerSobre .containerFundadores .left {
  padding: 100px 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
}

.containerSobre .containerFundadores .left .title {
  font-size: 2.2rem;
  font-weight: 900;
  max-width: 500px;
  color: #3d7d40;
  align-self: flex-start;
  margin-bottom: 25px;
}

.containerSobre .containerFundadores .left .subTitle {
  font-size: 1.4rem;
  font-weight: 700;
  align-self: flex-start;
  color: #2a1710;
}

.containerSobre .containerFundadores .left .containerText {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 530px;
}

.containerSobre .containerFundadores .left .containerText span {
  font-size: 1.1rem;
}

.containerSobre .containerFundadores .rigth {
  padding: 100px 50px;
  display: flex;
}

.containerSobre .containerFundadores .rigth img {
  width: 500px;
  border-radius: 15px;
  border: 2px solid #76482b;
}

.containerSobre .containerInfos {
  width: 1050px;
  background-color: #76482b;
  border: 2px solid #2a1710;
  padding: 45px 40px;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  align-self: center;
  gap: 10px;
  margin-bottom: 100px;
}

.containerSobre .containerInfos .card {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.containerSobre .containerInfos .card .iconCard {
  width: 80px;
}

.containerSobre .containerInfos .card .iconCard fill {
  background-color: #fff;
  color: #fff;
}

.containerSobre .containerInfos .card .titleCard {
  font-size: 3.5rem;
  font-weight: 700;
  color: #fff;
}

.containerSobre .containerInfos .card .subTitleCard {
  font-size: 1.3rem;
  font-weight: 500;
  color: #fff;
  width: 170px;
}

.containerSobre .arcBioChoiceContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin-bottom: 100px;
}

.containerSobre .arcBioChoiceContainer .header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerSobre .arcBioChoiceContainer .header .title {
  font-size: 2.2rem;
  font-weight: 700;
  max-width: 500px;
  color: #3d7d40;
}

.containerSobre .arcBioChoiceContainer .header .subTitle {
  font-size: 1.4rem;
  font-weight: 700;
  color: #2a1710;
}

.containerSobre .arcBioChoiceContainer .content {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.containerSobre .arcBioChoiceContainer .content .imageCasa {
  width: 500px;
  height: 380px;
  border-radius: 15px;
  border: 2px solid #76482b;
}

.containerSobre .arcBioChoiceContainer .content .containerCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
}

.containerSobre .arcBioChoiceContainer .content .containerCard .card {
  display: flex;
  align-items: center;
  gap: 20px;
}

.containerSobre .arcBioChoiceContainer .content .containerCard .card img {
  width: 40px;
  transform: scaleX(-1);
}

.containerSobre .arcBioChoiceContainer .content .containerCard .card .containerText {
  max-width: 450px;
}

.containerSobre .arcBioChoiceContainer .content .containerCard .card .containerText .title {
  font-size: 1.2rem;
  font-weight: 700;
  color: #3d7d40;
}

.containerSobre .arcBioChoiceContainer .content .containerCard .card .containerText .subTitle {
  font-size: 1.1rem;
}

.containerSobre .arcBioChoiceContainer .content .containerCard .buttonCard {
  background-color: #3d7d40;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  width: 300px;
  font-size: 18px;
  color: #fff;
  margin-top: 10px;
  cursor: pointer;
  font-family: "Montserrat", serif !important;
  transition: transform 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.containerSobre .arcBioChoiceContainer .content .containerCard .buttonCard:hover {
  transform: scale(1.1);
  /* Aumenta o tamanho em 10% */
}

.containerSobre .containerInfos .line {
  display: none;
}

.containerSobre .checkMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerSobre strong {
  color: #2a1710;
}


@media screen and (max-width: 1308px) {
  .containerSobre .containerFundadores .left .title {
    font-size: 2rem;
    font-weight: 900;
    max-width: 500px;
    color: #3d7d40;
    align-self: flex-start;
    margin-bottom: 25px;
  }

  .containerSobre .containerFundadores .left .subTitle {
    font-size: 1.2rem;
    font-weight: 700;
    align-self: flex-start;
  }

  .containerSobre .containerFundadores .left .containerText span {
    font-size: 1rem;
  }

  .containerSobre .containerFundadores .rigth img {
    width: 400px;
    border-radius: 15px;
    border: 2px solid #76482b;
  }

  .containerSobre .containerFundadores .rigth {
    padding: 80px 10px;
    display: flex;
  }
}

@media screen and (max-width: 1058px) {
  .containerSobre .containerFundadores .left .title {
    font-size: 1.8rem;
  }

  .containerSobre .containerFundadores .left .subTitle {
    font-size: 1.2rem;
  }

  .containerSobre .containerFundadores .left .containerText span {
    font-size: 1rem;
  }

  .containerSobre .containerFundadores .rigth img {
    width: 350px;
    border-radius: 15px;
    border: 2px solid #76482b;
  }

  .containerSobre .containerFundadores .rigth {
    padding: 80px 10px;
    display: flex;
  }

  .containerSobre .containerInfos {
    width: 100vw;
    background-color: transparent;
    border: none;
    padding: 0;
    border-radius: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    align-self: center;
    gap: 10px;
  }

  .containerSobre .containerInfos .card {
    width: 75vw;
    background-color: #76482b;
    border: 2px solid #2a1710;
    padding: 30px 0px;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    gap: 10px;
  }

  .containerSobre .containerInfos .line {
    display: flex;
    width: 2px;
    height: 50px;
    background-color: #2a1710;
  }

  .containerSobre .containerInfos .card .titleCard {
    font-size: 3rem;
  }

  .containerSobre .containerInfos .card .subTitleCard {
    font-size: 1.2rem;
  }

  .containerSobre .arcBioChoiceContainer .content {
    flex-direction: column;
  }

  .containerSobre .arcBioChoiceContainer .content .containerCard .buttonCard {
    align-self: center;
  }
}

@media screen and (max-width: 984px) {
  .containerSobre .containerFundadores {
    flex-direction: column;
  }

  .containerSobre .containerFundadores .left {
    padding: 0px 30px;
    margin-top: 70px;
  }

  .containerSobre .containerFundadores .rigth {
    padding: 0px 30px;
    margin-top: 40px;
    justify-content: center;
  }

  .containerSobre .containerFundadores .rigth img {
    width: 90%;
  }

  .containerSobre .containerInfos {
    position: absolute;
    bottom: -500px;
  }

  .containerSobre .containerInfos .card {
    width: 50vw;
    background-color: #76482b;
    border: 2px solid #2a1710;
    padding: 30px 0px;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    gap: 20px;
  }

  .containerSobre .checkMobile {
    position: relative;
    margin-bottom: 480px;
  }
}

@media screen and (max-width: 667px) {
  .containerSobre .containerFundadores {
    flex-direction: column;
  }

  .containerSobre .containerFundadores .left {
    padding: 0px 8px;
  }

  .containerSobre .containerFundadores .rigth {
    padding: 0px 10px;
  }

  .containerSobre .containerInfos .card {
    width: 65vw;

  }


  .containerSobre .checkMobile {
    position: relative;
    /* margin-bottom: 500px; */
  }

  .containerSobre .containerInfos .card .titleCard {
    font-size: 2.5rem;
    font-weight: 700;
    color: #fff;
  }

  .containerSobre .containerInfos .card .subTitleCard {
    font-size: 0.9rem;
    font-weight: 500;
    color: #fff;
    width: 110px;
  }

  .containerSobre .containerFundadores .left .title {
    font-size: 1.4rem;

  }

  .containerSobre .arcBioChoiceContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-bottom: 100px;
  }

  .containerSobre .arcBioChoiceContainer .header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .containerSobre .arcBioChoiceContainer .header .title {
    font-size: 1.6rem;
  }

  .containerSobre .arcBioChoiceContainer .header .subTitle {
    font-size: 1.2rem;
  }

  .containerSobre .arcBioChoiceContainer .content {
    display: flex;
    justify-content: center;
    gap: 40px;
  }

  .containerSobre .arcBioChoiceContainer .content .imageCasa {
    width: 80%;
    height: auto;
    align-self: center;
  }

  .containerSobre .arcBioChoiceContainer .content .containerCard {
    width: 90vw;
    align-self: center;
  }

  .containerSobre .arcBioChoiceContainer .content .containerCard .card {
    align-self: flex-start;
  }

  .containerSobre .arcBioChoiceContainer .content .containerCard .card img {
    width: 30px;
  }

  .containerSobre .arcBioChoiceContainer .content .containerCard .card .containerText {
    max-width: 500px;
  }

  .containerSobre .arcBioChoiceContainer .content .containerCard .card .containerText .title {
    font-size: 1.2rem;
    font-weight: 700;
    color: #3d7d40;
  }

  .containerSobre .arcBioChoiceContainer .content .containerCard .card .containerText .subTitle {
    font-size: 1rem;
  }

  .containerSobre .arcBioChoiceContainer .content .containerCard .buttonCard {
    background-color: #3d7d40;
    padding: 10px 30px;
    border: none;
    border-radius: 50px;
    width: 200px;
    font-size: 0.8rem;
    margin-top: 20px;
  }
}