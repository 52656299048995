.containerPortifolio {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    margin-bottom: 100px;
    position: relative;
}

.containerPortifolio .header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerPortifolio .header .title {
    font-size: 2.2rem;
    font-weight: 700;
    color: #3d7d40;
    text-align: center;
}

.containerPortifolio .header .subTitle {
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    color: #2a1710;
}

.containerPortifolio .photos {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 70px;
}


.containerPortifolio .photos .containerPhotos {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between
}

.containerPortifolio .photos .containerPhotos .card {
    height: 180px;
    flex: 1;
    background-color: #76482b;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.containerPortifolio .photos .containerPhotos .card2 {
    height: 180px;
    flex: 1;
    background-color: #76482b;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}


.containerPortifolio .photos .containerPhotos .image1 {
    height: 180px;
    border-radius: 10px;
    object-fit: cover;
    /* Garante que a imagem preencha sem distorcer */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    /* Adiciona a sombra */
    transition: transform 0.3s ease;
    /* Suaviza o efeito de hover */
    cursor: pointer;
}

.containerPortifolio .photos .containerPhotos .image1:hover {
    transform: scale(1.05);
    /* Aumenta levemente no hover */
}

.containerPhotos>div,
.containerPhotos>img {
    margin: 5px;
    /* Ajuste conforme necessário para o espaçamento */
}

/* Alinha a última linha para o flex-end */
.containerPhotos:last-child {
    justify-content: flex-end;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-image {
    width: 50%;
    height: auto;
    border-radius: 8px;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
}

.close-button:hover {
    background: #ccc;
}


@media screen and (max-width: 667px) {
    .containerPortifolio .header .title {
        font-size: 1.6rem;
        text-align: center;
        width: 90vw;
    }

    .containerPortifolio .header .subTitle {
        font-size: 1.2rem;
        text-align: center;
        width: 90vw;
    }

    .containerPortifolio .photos {
        margin-top: 40px;
    }


    .containerPortifolio .photos .containerPhotos {
        gap: 0px;
    }

    .containerPortifolio .photos .containerPhotos .card {
        display: none;
    }

    .containerPortifolio .photos .containerPhotos .card2 {
        display: none;
    }

    .containerPortifolio .photos .containerPhotos .cardImage {
        height: auto;
        width: 47%;
    }

    .containerPortifolio .photos .containerPhotos .image1 {
        /* height: auto; */
        width: 100%;
        border-radius: 10px;
    }

    .modal-image {
        width: 80%;
        height: auto;
        border-radius: 8px;
    }
}