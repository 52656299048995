.containerFooter {
    display: flex;
    gap: 80px;
    align-items: center;
    /* min-height: 300px; */
    padding: 80px;
    justify-content: center;
}

.containerFooter img {
    width: 150px;
}

.containerFooter .direitos {
    width: 350px;
    font-weight: 400;
    font-size: 1rem;
}

.containerFooter .endereco {
    width: 320px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.containerFooter .endereco .principal {
    font-size: 1rem;
    font-weight: 700;
    color: #3d7d40;
}

.containerFooter .endereco .secundario {
    font-size: 1rem;
}

.containerFooter .endereco .containerSocial {
    display: flex;
    gap: 30px;
    font-size: 1rem;
}

.containerFooter .line {
    display: none;
}

.containerFooter .containerSOcial {
    height: 30px;
    width: 30px;
    background-color: #3d7d40;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .containerFooter .containerSOcial img {
    height: 40px;
    width: 40px;
} */

.mobile {
    display: none;
}


@media screen and (max-width: 1134px) {
    .containerFooter {
        gap: 50px;
        padding: 50px;
    }
    
    .containerFooter img {
        width: 130px;
    }
    
    .containerFooter .direitos {
        width: 350px;
        font-weight: 400;
        font-size: 1rem;
    }
    
    .containerFooter .endereco {
        width: 320px;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    
    .containerFooter .endereco .principal {
        font-size: 1rem;
    }
    
    .containerFooter .endereco .secundario {
        font-size: 1rem;
    }
    
    .containerFooter .endereco .containerSocial {
        font-size: 1rem;
    }
}

@media screen and (max-width: 996px) {
    .web {
        display: none;
    }

    .mobile {
        display: flex;
        margin-bottom: 60px;
    }
    
    .containerFooter {
        flex-direction: column;
        text-align: center;
    }

    .containerFooter .endereco .containerSocial {
        flex-direction: column;
        align-items: center;
    }

    .containerFooter .line {
        display: flex;
        width: 70vw;
        height: 3px;
        background-color: #74482d;
    }

    .containerFooter .direitos {
        width: 300px;
    }

    .containerFooter .endereco {
        width: 300px;
    }

    

    .containerFooter .direitos {
        font-size: 0.9rem;
    }
    
    .containerFooter .endereco .principal {
        font-size: 0.9rem;
    }
    
    .containerFooter .endereco .secundario {
        font-size: 0.9rem;
    }
    
    .containerFooter .endereco .containerSocial {
        font-size: 0.9rem;
    }
}