.containerClientes {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 100px; */
  width: 100vw;
  background-image: url(../../Assets/textura-papel-reciclado.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.containerClientes .header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.containerClientes .header .title {
  font-size: 2.2rem;
  font-weight: 700;
  color: #3d7d40;
}

.containerClientes .header .subTitle {
  font-size: 1.4rem;
  font-weight: 700;
  color: #2a1710;
}

.containerClientes .containerCards {
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 100px;
}

.containerClientes .containerCards .card {
  width: 400px;
  border: 2px solid #734a2b;
  padding: 25px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.containerClientes .containerCards .card .text {
  font-weight: 500;
}

.containerClientes .containerCards .card .name {
  font-weight: 700;
  color: #3d7d40;
  margin-top: 20px;
}

.containerClientes .containerCards .card .local {
  font-weight: 500;
}

.containerClientes .containerFundadores {
  display: flex;
  /* width: 90vw; */
  gap: 20px;
  justify-content: center;
  align-items: center;
}


.containerClientes .containerFundadores .left {
  padding: 100px 50px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
}

.containerClientes .containerFundadores .left .title {
  font-size: 2.2rem;
  font-weight: 900;
  max-width: 500px;
  color: #3d7d40;
  align-self: flex-start;
  margin-bottom: 10px;
}

.containerClientes .containerFundadores .left .subTitle {
  font-size: 1.4rem;
  font-weight: 700;
  align-self: flex-start;
  color: #2a1710;
}

.containerClientes .containerFundadores .left .containerText {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;
}

.containerClientes .containerFundadores .left .containerText span {
  font-size: 1.1rem;
}

.containerClientes .containerFundadores .rigth {
  padding: 100px 50px;
  display: flex;
}

.containerClientes .containerFundadores .rigth img {
  width: 600px;
  border-radius: 15px;
  border: 3px solid #76482b;
}

.containerClientes .containerFundadores .left .buttonCard {
  background-color: #3d7d40;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  width: 300px;
  font-size: 18px;
  color: #fff;
  margin-top: 35px;
  cursor: pointer;
  font-family: "Montserrat", serif !important;
  transition: transform 0.3s ease;
  align-self: flex-start;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.containerClientes .containerFundadores .left .buttonCard:hover {
  transform: scale(1.1);
  /* Aumenta o tamanho em 10% */
}

.containerClientes strong {
  color: #2a1710;
}

.containerClientes .containerFundadores .left img {
  display: none;
}

@media screen and (max-width: 1199px) {
  .containerClientes .containerFundadores .left .title {
    font-size: 2rem;
  }

  .containerClientes .containerFundadores .left .subTitle {
    font-size: 1.2rem;
  }

  .containerClientes .containerFundadores .rigth img {
    width: 500px;
  }
}

@media screen and (max-width: 1040px) {
  .containerClientes .containerFundadores .rigth img {
    width: 60vw;
    display: none;
  }

  .containerClientes .containerFundadores .left img {
    width: 90vw;
    border-radius: 15px;
    border: 3px solid #76482b;
    margin-bottom: 25px;
    margin-top: 15px;
    display: flex;
  }

  .containerClientes .containerFundadores {
    flex-direction: column-reverse;
    align-items: center;
  }

  .containerClientes .containerFundadores .left {
    padding: 20px;
    align-items: center;
  }

  .containerClientes .containerFundadores .left .title {
    font-size: 2.2rem;
  }

  .containerClientes .containerFundadores .left .subTitle {
    font-size: 1.4rem;
  }

  .containerClientes .containerFundadores .left .containerText {
    max-width: 500px;
    align-items: center;
    align-self: center;
  }

  .containerClientes .containerFundadores .left .containerText span {
    text-align: center;
    font-size: 1.1rem;
  }

  .containerClientes .containerFundadores .rigth {
    padding: 20px;
  }

}


@media screen and (max-width: 667px) {
  .containerClientes .containerFundadores .rigth img {
    width: 80vw;
  }


  .containerClientes .containerFundadores .left {
    padding: 10px;
    align-items: center;
  }

  .containerClientes .containerFundadores .left .title {
    font-size: 1.6rem;
  }

  .containerClientes .containerFundadores .left .subTitle {
    font-size: 1.2rem;
  }

  .containerClientes .containerFundadores .left .containerText span {
    font-size: 1rem;
  }

  .containerClientes .containerFundadores .rigth {
    padding: 10px;
  }

  .containerClientes .containerFundadores .left .containerText {
    width: 80vw;
  }

  .containerClientes .containerFundadores .left .buttonCard {
    margin-bottom: 50px;
  }

  .containerClientes .header {
    margin-top: 70px;
  }

  .containerClientes .header .title {
    font-size: 1.6rem;
  }

  .containerClientes .header .subTitle {
    font-size: 1.2rem;
  }

  .containerClientes .containerCards {
    margin-bottom: 40px;
  }

  .containerClientes .containerCards .card {
    width: 75vw;
  }

  .containerClientes .containerCards .card .text {
    font-size: 0.9rem;
  }

    .containerClientes .containerFundadores .left .buttonCard {
    background-color: #3d7d40;
    padding: 8px 20px;
    border: none;
    border-radius: 50px;
    width: 200px;
    font-size: 0.8rem;
    margin-top: 20px;
  }

}