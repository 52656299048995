.containerHome {
  width: 100%;
  height: 100svh; /* Altura visível excluindo barras do navegador */
  background-image:
    linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)),
    url(../../Assets/residencia-litoranea-rustica-sustentavel-blur.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}


.containerHome .headerMenu {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 90vw;
  padding-top: 70px;
}

.containerHome .headerMenu img {
  width: 140px;
}

.containerHome .nav {}

.containerHome .ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerHome .li {
  margin: 0 15px;
  /* width: 170px; */
  /* text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #3d7d40; */
}

.containerHome .link {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
}

.containerHome .link:hover {
  /* text-decoration: underline; */
  /* color: #346732; */
  font-weight: 700;
}

.containerHome .section {
  padding: 100px 20px;
  height: 100vh;
  /* A altura de cada seção será 100% da altura da tela */
}

.containerHome .content {
  display: flex;
  flex-direction: column;
  max-width: 540px;
  color: #fff;
  padding-bottom: 100px;
  align-items: center;
}

.containerHome .content h1 {
  font-size: 1.9rem;
  text-align: center;
}

.containerHome .content .subTitle {
  font-size: 1.4rem;
  text-align: center;
}

.containerHome .buttonHome {
  background-color: #3d7d40;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  width: 300px;
  font-size: 18px;
  color: #fff;
  margin-top: 35px;
  cursor: pointer;
  font-family: "Montserrat", serif !important;
  transition: transform 0.3s ease; /* Adiciona uma transição suave */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.containerHome .buttonHome:hover {
  transform: scale(1.1); /* Aumenta o tamanho em 10% */
}

@media screen and (max-width: 702px) {
  .containerHome {
    background-image:
      linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)),
      linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)),
      url(../../Assets/Arcbio_mobile\ lp\ \(1\)\ \(1\).png);
  }
  
  .containerHome .nav {
    display: none;
  }

  .containerHome .content {
    width: 80vw;
  }

  .containerHome .content h1 {
    font-size: 1.1rem;
    text-align: center;
  }
  
  .containerHome .content .subTitle {
    font-size: 0.8rem;
    text-align: center;
  }

  .containerHome .buttonHome {
    background-color: #3d7d40;
    padding: 8px 20px;
    border: none;
    border-radius: 50px;
    width: 200px;
    font-size: 0.8rem;
    margin-top: 20px;
  }

  .containerHome .content {
    padding-bottom: 60px;
  }

  .containerHome .headerMenu {
    padding-top: 25px;
  }

  .containerHome .headerMenu img {
    width: 120px;
  }
}